import React from "react";
import ReactDOM from "react-dom";
import './css/Header.scss';

export function Header() {
    return (
        <div className="ckb-navigation">
          <div className="nav-top">
            <div className="logo-top"><a href="index.html" className="ckb-logo"><span>C. K. Bilstad</span></a></div>
            <div className="topmenu">
              <div className="block">
              
              <a href="christian.html" className="link-2 first">
                <span className="mask">
                  <div className="link-container">
                    <span className="link-title1 title">Om Christian</span>
                    <span className="link-title2 title">Om Christian</span>
                  </div>
                </span>
              </a>
            </div>
            </div>
          </div>
        </div>
    );
  }

const app = document.getElementById("header");
ReactDOM.render(<Header />, header);